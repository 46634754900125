import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from "react"
import PropTypes from "prop-types"

const AddressFinderInput = forwardRef(
  ({ id, name, placeholder, onAddressSelected, apiKey, country }, ref) => {
    const inputRef = useRef(null)
    const widgetRef = useRef(null)

    useEffect(() => {
      const script = document.createElement("script")
      script.src = "https://api.addressfinder.io/assets/v3/widget.js"
      script.async = true

      script.onload = () => {
        if (window.AddressFinder) {
          widgetRef.current = new window.AddressFinder.Widget(
            inputRef.current,
            apiKey,
            country
          )

          widgetRef.current.on("result:select", event => {
            if (onAddressSelected) {
              onAddressSelected(event)
            }
          })
        }
      }

      document.body.appendChild(script)

      return () => {
        document.body.removeChild(script)
      }
    }, [apiKey, country, onAddressSelected])

    // Expose a reset method to parent components
    useImperativeHandle(ref, () => ({
      clear: () => {
        if (inputRef.current) {
          inputRef.current.value = ""
        }
      },
    }))

    return (
      <input
        id={id}
        name={name}
        ref={inputRef}
        className="form-control"
        placeholder={placeholder}
      />
    )
  }
)

AddressFinderInput.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onAddressSelected: PropTypes.func.isRequired,
  apiKey: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
}

AddressFinderInput.defaultProps = {
  placeholder: "Search your address",
}

export default AddressFinderInput
