export const aggregatorOptions = [
  { label: "Select your aggregator", value: "" },
  { label: "(ex Vow) YBR Aggregation", value: "Vow" },
  { label: "AFG", value: "AFG" },
  { label: "Astute", value: "Astute" },
  { label: "Buyers Choice (PLAN)", value: "Buyers Choice" },
  { label: "Connective", value: "Connective" },
  { label: "Direct Accreditation", value: "Direct Accreditation" },
  { label: "FHG/Your Local Broker", value: "FHG/Your Local Broker" },
  { label: "Finsure", value: "Finsure" },
  {
    label: "Freedom Aggregation (Choice)",
    value: "Freedom Aggregation (Choice)",
  },
  { label: "LMG (CHOICE)", value: "CHOICE" },
  { label: "LMG (FAST)", value: "FAST" },
  { label: "LMG (Loan Market)", value: "Loan Market" },
  { label: "LMG (PLAN)", value: "PLAN" },
  { label: "Loankit", value: "Loankit" },
  { label: "MoneyQuest (Choice)", value: "Money Quest" },
  { label: "Mortgage Choice", value: "Mortgage Choice" },
  { label: "Mortgage Choice (Smartline Heritage Broker)", value: "Smartline" },
  { label: "My Local Broker", value: "My Local Broker" },
  { label: "None of the Above", value: "None of the Above" },
  { label: "Outsource Financial", value: "Outsource Financial" },
  { label: "Purple Circle / SFG", value: "Purple Circle" },
  { label: "YBR", value: "YBR" },
]
